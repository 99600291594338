<template>
    <svg width="20" height="20" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z"></path>
            <path d="M5.5 4h13A1.5 1.5 0 0120 5.5v4a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 014 9.5v-4A1.5 1.5 0 015.5 4zM11 6a1 1 0 000 2h2a1 1 0 000-2h-2z" fill="#7e8697" opacity=".3"></path>
            <path d="M5.5 13h13a1.5 1.5 0 011.5 1.5v4a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 014 18.5v-4A1.5 1.5 0 015.5 13zm5.5 2a1 1 0 000 2h2a1 1 0 000-2h-2z" fill="#7e8697"></path>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'Commode'
};
</script>