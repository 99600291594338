<template>
  <Collapse
    data-title="Filter Automation Recipes"
    data-intro=""
    data-step="1"
    :title="title"
  >
    <button
      v-for="category in categories"
      :key="category.name"
      class="btn btn-sm btn-outline-secondary d-flex text-left align-items-center lift mb-2"
      style="width: 100%"
      @click="filterCategory(category.name)"
      exact
    >
      {{ category.name }}
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-arrow-right-short ml-auto"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
        />
      </svg>
    </button>
    <button
      v-if="categories.length > 0"
      class="btn btn-sm btn-outline-secondary text-left mb-2 w-100"
      @click="filterCategory('')"
    >
      Reset filters
    </button>
  </Collapse>
</template>

<script>
import Collapse from "@/components/UI/Collapse.vue";

export default {
  name: "RecipeFilter",
  components: {
    Collapse,
  },
  data() {
    return {
      title: "Categories",
      expandCollapse: false,
      categories: [
          {
              name: "Customer Email"
          },
          {
              name: "Order Tags & Lists"
          },
          {
              name: "CRM Tags & Lists"
          }
      ]
    };
  },
  methods: {
      filterCategory(category_name) {
        this.$emit("filter", category_name);
      }
  }
};
</script>

<style scoped>
.btn-block.active svg {
  transform: rotate(180deg);
}
</style>
