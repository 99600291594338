<template>
  <div class="card border-0 shadow-sm mb-6">
    <div
      class="card-header d-flex flex-column flex-md-row justify-content-between align-items-lg-center align-items-md-start"
    >
      <div class="px-0 py-1 m-1">
        <p>
          Recipes
          <Tooltip
            :title="
              'Install and configure these pre-built standard automations. This is a good way of getting effective automations out-of-the-box and to learn Automation.app in no time.'
            "
          />
        </p>
      </div>
      <div class="px-0 py-1 m-0">
        <router-link
          :to="{ name: 'automations.create' }"
          class="btn btn-sm btn-primary m-1"
        >
          Build automation
        </router-link>
      </div>
    </div>
    <div
      v-if="recipes.length !== 0"
      class="card-body"
    >
      <div v-for="recipe in automationrecipes" :key="recipe.id">
        <div class="row">
          <div class="col-2 col-md-2 text-center">
            <div v-if="recipe.category == 'Customer Email'">
              <img
                src="@/assets/detail1.svg"
                style="width: 100%; height: auto;max-height: 150px"
              />
            </div>
            <div v-else-if="recipe.category == 'Order Tags & Lists'">
              <img
                src="@/assets/detail2.svg"
                style="width: 100%; height: auto;max-height: 150px"
              />
            </div>
            <div v-else-if="recipe.category == 'CRM Tags & Lists'">
              <img
                src="@/assets/detail3.svg"
                style="width: 100%; height: auto;max-height: 150px"
              />
            </div>
            <div v-else>
              <img
                src="@/assets/detail2.svg"
                style="width: 100%; height: auto;max-height: 150px"
              />
            </div>
          </div>
          <div class="col-10 col-md-8">
            <h4 class="card-title text-body">
              {{ recipe.name }}
            </h4>
            <p class="card-text text-muted">{{ recipe.description }}</p>
            <span class="text-muted mr-5">
              <Commode />
              {{ recipe.category }}
            </span>
            <br class="d-block d-sm-none" />
            <span class="text-muted">
              <Puzzle />
              {{ getEventName(recipe.event) }}
            </span>
          </div>
          <div class="col-12 col-md-2">
            <button
              type="submit"
              class="btn btn-sm btn-outline-secondary m-1"
              @click="installRecipe(recipe.slug)"
            >
              Install
            </button>
          </div>
        </div>
        <hr />
      </div>
    </div>
    <div v-else class="card-body">
      <p class="text-muted">
        This list displays the automation recipes we offer.
      </p>
      <div class="p-4 rounded border-zip text-center">
        <p class="mb-0">
          No automation recipes available.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { loadFromApi } from "@/helpers/apiConnection";
import Puzzle from "@/components/Svg/Puzzle.vue";
import Commode from "@/components/Svg/Commode.vue";
import events from "@/helpers/events";
import { EventName } from "@/helpers/automationUtilities";
import Tooltip from "@/components//UI/Tooltip.vue";

export default {
  name: "Recipes",
  components: {
    Puzzle,
    Commode,
    Tooltip,
  },
  props: {
    categoryName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data: function() {
    return {
      recipes: [],
      event_name: "",
    };
  },
  computed: {
    automationrecipes: function() {
      if (this.categoryName) {
        const filtered = this.recipes.filter((rp) => {
          return rp.category === this.categoryName;
        });
        return filtered;
      } else {
        return this.recipes;
      }
    },
  },
  async mounted() {
    const recipes = await loadFromApi("/setup/recipe");
    if (recipes) {
      this.recipes = recipes;
    }
  },
  methods: {
    installRecipe(slug) {
      const recipe = this.recipes.find((element) => element.slug === slug);
      this.$store.dispatch("SET_RECIPE_TO_INSTALL", recipe);
      this.$router.push({ name: "automations.recipe" });
    },
    getEventName(event_id) {
      return EventName(events, event_id);
    },
  },
};
</script>

<style scoped></style>
