<template>
  <component :is="layout">
    <div class="d-flex">
      <OverviewSidebar
        ref="OverviewSidebar"
        class="position-fixed p-4 justify-content-between fadeInUp"
        overview="recipe"
        hide-views
        hide-filters
        hide-tags
      >
        <RecipeFilter slot="sidebar" @filter="filterCategory" />
      </OverviewSidebar>
      <div class="p-5 flex-fill fadeInUp has-sidebar" role="main">
        <HelpModal class="position-fixed p-4 justify-content-between" v-if="helpModal" @close="openHelp">
          <p>
            Here you can find the prebuilt automations we have provided for you.
          </p>
        </HelpModal>
        <Recipes :category-name="category_name" />
        <div class="page-help">
          <a @click="openHelp">
            <QuestionCircle />
          </a>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import Default from "@/layouts/default.vue";
import Recipes from "@/components/Automation/Recipes.vue";
import OverviewSidebar from "@/components/Order/OverviewSidebar.vue";
import RecipeFilter from "@/components/Order/RecipeFilter.vue";
import QuestionCircle from "@/components/Svg/QuestionCircle.vue";
import HelpModal from "@/components/UI/HelpModal.vue";

export default {
  components: {
    Default,
    Recipes,
    OverviewSidebar,
    RecipeFilter,
    QuestionCircle,
    HelpModal,
  },

  data: function() {
    return {
      layout: "Default",
      loading: false,
      category_name: "",
      helpModal: false,
    };
  },
  methods: {
    filterCategory(category_name) {
      this.category_name = category_name;
    },
    openHelp() {
      this.helpModal = !this.helpModal;
      window.scrollTo(0,0);
    },
  },
};
</script>
