<template>
    <svg width="20" height="20" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <path d="M0 0h24v24H0z"></path>
            <path d="M19 11h1a3 3 0 010 6h-1v3a2 2 0 01-2 2H5a2 2 0 01-2-2v-3h2a3 3 0 000-6H3V8a2 2 0 012-2h3V5a3 3 0 116 0v1h3a2 2 0 012 2v3z" fill="#7e8697" opacity=".3"></path>
        </g>
    </svg>
</template>

<script>
export default {
  name: 'Puzzle'
};
</script>